import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { postChannelRequest } from 'store/channelRequests'
import ChannelRequestForm from 'components/ChannelRequestForm'

class NewChannelRequest extends React.Component {
  constructor(props) {
    super(props)

    // Remember the number of existing requests when the component loaded
    this.state = {
      initialRequestCount: props.channelRequests.length
    }
  }

  render() {
    const requests = this.props.channelRequests

    // Any new channels? That means the submit went through, so show it.
    if (requests.length > this.state.initialRequestCount) {
      return (
        <Redirect
          to={'/channel-requests/' + requests[requests.length - 1].id}
        />
      )
    }

    return (
      <ChannelRequestForm
        title="Request or Suggest a New Channel"
        appId="uclamobile"
        onSubmit={this.submit}
      />
    )
  }

  submit = (fields) => {
    this.props.postChannel(fields)
  }
}

const mapStateToProps = state => ({
  channelRequests: state.channelRequests
})

const mapDispatchToProps = dispatch => ({
  postChannel: fields => dispatch(postChannelRequest(fields))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewChannelRequest)
