import config from 'config'

const SET_ZAP_MODULES = 'SET_ZAP_MODULES'

export const setZapModules = (zapModules) => ({
  type: SET_ZAP_MODULES,
  zapModules,
})

export const zapModules = (state = null, action) => {
  switch (action.type) {
    case SET_ZAP_MODULES:
      return action.zapModules

    default:
      return state
  }
}

export const fetchZapModules = (appId) => async (dispatch, state) => {
  const org = config.zap.orgs[appId]
  if (!org) {
    dispatch(setZapModules([]))
  }

  const url = config.zap.baseUrl + '/' + org + '/modules'
  const response = await fetch(url)

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setZapModules(json))
  }
}
