const config = {
  keycloak: {
    url: 'https://staging.bivrost.oit.ucla.edu',
    realm: 'shibboleth',
    clientId: 'messaging'
  },
  api: {
    baseUrl: 'https://staging.msg.apps.ucla.edu/apps'
  },
  zap: {
    baseUrl: 'https://staging.zap.ucla.edu/api/apps/',
    orgs: {
      uclamobile: 'ucla',
      'ucla-health': 'ucla-health-v2',
    },
  },
  logoutUrl: 'https://shb.ais.ucla.edu/shibboleth-idp/logout?' +
    'entityId=https%3A%2F%2Fstaging.bivrost.oit.ucla.edu%2Frealms%2Fshibboleth'
}

module.exports = config
