import config from 'config'
import toaster from 'toaster'

const SET_CHANNEL_REQUESTS = 'SET_CHANNEL_REQUESTS'
const ADD_CHANNEL_REQUEST = 'ADD_CHANNEL_REQUEST'

export const setChannelRequests = channelRequests => ({
  type: SET_CHANNEL_REQUESTS,
  channelRequests
})

export const addChannelRequest = channelRequest => ({
  type: ADD_CHANNEL_REQUEST,
  channelRequest
})

export const channelRequests = (state = [], action) => {
  switch (action.type) {
    case SET_CHANNEL_REQUESTS:
      return action.channelRequests

    case ADD_CHANNEL_REQUEST:
      return state.concat(action.channelRequest)

    default:
      return state
  }
}

export const fetchChannelRequests = (appId) => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/channel-requests'
  const token = state().user.token

  const response = await fetch(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setChannelRequests(json))
  }
}

export const fetchChannelRequest = (appId, channelRequestId) => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/channel-requests/' + channelRequestId
  const token = state().user.token

  const response = await fetch(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setChannelRequests([json]))
  }
}

export const postChannelRequest = fields => async (dispatch, state) => {
  let url = config.api.baseUrl + '/uclamobile/channel-requests'
  let verb = 'POST'
  const token = state().user.token

  let params = {
    sponsored: fields.sponsored,
    displayName: fields.displayName,
    department: fields.department,
    email: fields.email,
    need: fields.need,
    description: fields.description,
    tags: fields.tags
  }

  const response = await fetch(url, {
    method: verb,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(params)
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()

    toaster.show({
      message: 'We will review your request and get back to you ASAP.'
    })

    dispatch(addChannelRequest(json))
  }
}
