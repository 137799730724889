import React from 'react'
import { connect } from 'react-redux'
import { H1, H2, Icon, Tabs, Tab } from '@blueprintjs/core'

import { selectApp, fetchAppChannels, fetchAppStats } from 'store/apps'
import { fetchMessages, deleteMessage } from 'store/messages'
import { fetchReceipts } from 'store/receipts'
import AppDropdown from 'components/AppDropdown'
import AppStats from 'components/AppStats'
import MessagesBarChart from 'components/MessagesBarChart'
import MessagesTable from 'components/MessagesTable'
import StyledButton from 'components/StyledButton'

import 'styles/Dashboard.css'

class Dashboard extends React.Component {
  componentDidMount() {
    const authorizedApps = this.props.apps.filter(app => (
      this.props.user.roles.some(role => role.startsWith(app.id))
    ))

    // If user only has access to one app, auto-select it
    if (authorizedApps.length === 1) {
      this.selectApp(authorizedApps[0].id)
    }

    // If an app is already selected, refresh it
    else {
      const selected = this.props.apps.find(app => app.selected)

      if (selected) {
        this.selectApp(selected.id)
      }
    }
  }

  render() {
    const selected = this.props.apps.find(app => app.selected)

    return (
      <div className="Dashboard">
        <div className="heading">
          {this.renderAppDropdown()}

          <H1>
            {selected && selected.displayName} Messaging
          </H1>

          <StyledButton
            to="/messages/new"
            colorClass="yellow"
            style={{ justifySelf: 'end' }}
          >
            <Icon icon="add" iconSize={Icon.SIZE_LARGE} />
            Create a new message
          </StyledButton>
        </div>

        <H2>
          Messaging Dashboard
        </H2>

        {this.renderAppStats()}

        <div className="charts">
          {this.renderScheduledChart()}
          {this.renderSentChart()}
        </div>

        {this.renderTabs()}
      </div>
    )
  }

  renderAppDropdown() {
    return (
      <AppDropdown
        id="app"
        apps={this.props.apps}
        roles={this.props.user.roles}
        onChange={this.onChangeApp}
      />
    )
  }

  renderAppStats() {
    const selected = this.props.apps.find(app => app.selected)

    if (!selected || !selected.channels || !selected.stats) {
      return null
    }

    // Grab stats
    const stats = {}
    selected.stats.forEach((channel) => {
      stats[channel.id] = channel.subscribers
    })

    return (
      <AppStats
        key={selected.id}
        iconURL={selected.iconURL}
        iconAltText={selected.displayName + ' logo'}
        channels={selected.channels}
        stats={stats}
      />
    )
  }

  renderScheduledChart() {
    const selected = this.props.apps.find(app => app.selected)

    if (!selected || !selected.channels) {
      return null
    }

    const scheduledMessages = this.props.messages.filter(message => (
      message.scheduled
    ))

    return (
      <MessagesBarChart
        type="scheduled"
        channels={selected.channels}
        messages={scheduledMessages}
      />
    )
  }

  renderSentChart() {
    const selected = this.props.apps.find(app => app.selected)

    if (!selected || !selected.channels) {
      return null
    }

    const sentMessages = this.props.messages.filter(message => (
      message.status === 'sent'
    ))

    return (
      <MessagesBarChart
        type="sent"
        channels={selected.channels}
        messages={sentMessages}
      />
    )
  }

  renderTabs() {
    const selected = this.props.apps.find(app => app.selected)

    // Wait until stats and channels are available
    if (!selected || !selected.channels) {
      return null
    }

    // Grab friendly channel names
    const channels = {}
    selected.channels.forEach((channel) => {
      channels[channel.id] = channel.displayName
    })

    const pendingMessages = this.props.messages.filter(message => (
      message.status === 'saved' || message.status === 'scheduled'
    ))
    const sentMessages = this.props.messages.filter(message => (
      message.status === 'sent'
    ))

    const pending = (
      <MessagesTable
        app={selected.id}
        user={this.props.user}
        type="pending"
        data={pendingMessages}
        channels={channels}
        onDeleteMessage={this.deleteMessage}
      />
    )

    const sent = (
      <MessagesTable
        app={selected.id}
        user={this.props.user}
        type="sent"
        data={sentMessages}
        channels={channels}
        receipts={this.props.receipts}
      />
    )

    return (
      <Tabs className="styled-tabs" animate={false}>
        <Tab id="pending" title="Pending Messages" panel={pending} />
        <Tab id="sent" title="Sent Messages" panel={sent} />
      </Tabs>
    )
  }

  selectApp(appId) {
    this.props.selectApp(appId)
    this.props.fetchAppChannels(appId)
    this.props.fetchAppStats(appId)
    this.props.fetchMessages(appId)
    this.props.fetchReceipts(appId)
  }

  onChangeApp = (event) => {
    const appId = event.currentTarget.value

    this.selectApp(appId)
  }

  deleteMessage = (messageId) => {
    const selected = this.props.apps.find(app => app.selected)

    this.props.deleteMessage(selected.id, messageId)
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  messages: state.messages,
  receipts: state.receipts,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  selectApp: appId => dispatch(selectApp(appId)),
  fetchAppChannels: appId => dispatch(fetchAppChannels(appId)),
  fetchAppStats: appId => dispatch(fetchAppStats(appId)),
  fetchMessages: appId => dispatch(fetchMessages(appId)),
  fetchReceipts: appId => dispatch(fetchReceipts(appId)),
  deleteMessage: (appId, messageId) => (
    dispatch(deleteMessage(appId, messageId))
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
